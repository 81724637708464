import './css.css';

function Element() {
    return (
        <footer id='footer'>
            <div className='noise' />
            <table className='about'>
                <tbody>
                    <tr>
                        <td>법인명</td>
                        <td><b>퍼스트씨엔디(주)</b></td>
                    </tr>
                    <tr>
                        <td>사업자번호</td>
                        <td><b>456-87-00223</b></td>
                    </tr>
                    <tr>
                        <td>대표자</td>
                        <td><b>이원기</b></td>
                    </tr>
                </tbody>
            </table>
            <br />
            <div className='copyright'>Copyright for <b>FIRST C&D</b> all rights reserved.</div>
        </footer>
    );
}

export default Element;
