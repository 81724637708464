import './css.css';
import SectionTitle from '../SectionTitle/element';
import useStore from '../data';
import { useEffect } from 'react';

function Element() {
    const { setIsMain } = useStore();
    useEffect(() => {
        reset(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const reset = () => {
        const root = document.getElementById('root');
        root.scrollTo(0, 0);
        setIsMain(false);
    };

    const { dataService, setDataService } = useStore();
    useEffect(() => {
        if (dataService.length === 0)
            fetch('https://docs.google.com/spreadsheets/d/1X7jDF7W9KbqEFGBbDe-Vuyg3zLNSXCspQ8H1E5glDtw/gviz/tq?tqx=out:json&gid=94095624')
                .then(response => response.text())
                .then(text => {
                    const json = JSON.parse(text.slice(47, -2));
                    const rows = json.table.rows;
                    setDataService(rows);
                })
                .catch(error => console.error('Error:', error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section id='service'>
            <div className='noise' />
            <SectionTitle title={'회사업무'} text={['개발기획부터 분양마케팅까지 전략 및 통합 솔루션을 제공합니다.']} />
            <div className='container'>
                {dataService.length > 0 && dataService.map((item, i) => (
                    <div key={i} className='item' style={{ animationDelay: `${250 * i}ms` }}>
                        <img className='img' src={item.c[1].v} alt='' />
                        <div className='title'><b>{item.c[2].v}</b></div>
                        <div className='text'>{item.c[3].v}</div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Element;
