import './css.css';
import SectionTitle from '../SectionTitle/element';
import useStore from '../data';
import { useEffect } from 'react';

function Element() {
    const { setIsMain } = useStore();
    useEffect(() => {
        reset(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const reset = () => {
        const root = document.getElementById('root');
        root.scrollTo(0, 0);
        setIsMain(false);
    };

    const { dataOrganization, setDataOrganization } = useStore();
    useEffect(() => {
        if (dataOrganization.length === 0)
            fetch('https://docs.google.com/spreadsheets/d/1X7jDF7W9KbqEFGBbDe-Vuyg3zLNSXCspQ8H1E5glDtw/gviz/tq?tqx=out:json&gid=1525315964')
                .then(response => response.text())
                .then(text => {
                    const json = JSON.parse(text.slice(47, -2));
                    const rows = json.table.rows;
                    setDataOrganization(rows);
                })
                .catch(error => console.error('Error:', error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { dataSubsidiary, setDataSubsidiary } = useStore();
    useEffect(() => {
        if (dataSubsidiary.length === 0)
            fetch('https://docs.google.com/spreadsheets/d/1X7jDF7W9KbqEFGBbDe-Vuyg3zLNSXCspQ8H1E5glDtw/gviz/tq?tqx=out:json&gid=95387645')
                .then(response => response.text())
                .then(text => {
                    const json = JSON.parse(text.slice(47, -2));
                    const rows = json.table.rows;
                    setDataSubsidiary(rows);
                })
                .catch(error => console.error('Error:', error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section id='organization'>
            <div className='noise' />
            <SectionTitle title={'회사조직'} text={['창조적이고 혁신적인 아이디어로 부동산 개발의 새로운 패러다임을 선보이겠습니다.']} />
            <div className='container'>
                {dataOrganization.length > 0 && dataOrganization.map((item, i) => (
                    <div key={i} className='item' style={{ animationDelay: `${250 * i}ms` }}>
                        {Array.from({ length: item.c[3].v }).map((_, j) => (
                            <div className='margin' key={j}>
                                <div className={`up ${j < item.c[3].v - 1 ? '' : 'left bottom'}`} />
                                <div className={`dn ${item.c[4].v ? '' : j < item.c[3].v - 1 ? '' : 'left'}`} />
                            </div>
                        ))}
                        <div className={`card lv${item.c[3].v}`}>
                            <div className='title'>
                                <i className={`icon fa-solid fa-${item.c[1].v.includes('본부') ? 'user-group' : 'user'}`} />
                                <b>{item.c[1].v}</b>
                            </div>
                            <div className='text'>
                                {item.c[2] && item.c[2].v}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='subsidiary'>
                <div className='title'><b>관계사</b></div>
                <div>
                    {dataSubsidiary.length > 0 && dataSubsidiary.map((item, i) => (
                        <span key={i}>
                            {i !== 0 && ' | '}{item.c[1].v}
                        </span>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Element;
