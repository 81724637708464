import './css.css';
import useStore from '../data';
import logo from '../logo.svg';
import { Link } from 'react-router-dom';
import { useState } from 'react';

function Element() {
    const { isMain } = useStore();
    const [isBarsActive, setBarsActive] = useState(false);

    return (
        <nav className={`nav ${isMain ? 'main' : ''}`}>
            <div className='noise' />
            <Link id='logo' to='/'>
                <img src={logo} alt='logo' />
                FIRST C&D
            </Link>
            <ul>
                <div className={isBarsActive ? 'bars active' : 'bars'} onClick={() => setBarsActive(!isBarsActive)}>
                    <i className={`fa-solid fa-${isBarsActive ? 'xmark' : 'bars'} fa-xl`} />
                </div>
                <li>
                    <Link to='/Service' onClick={() => setBarsActive(false)}>
                        <b>회사소개</b>
                    </Link>
                    <ul className='links'>
                        <li>
                            <Link to='/Service' onClick={() => setBarsActive(false)}>
                                회사업무
                            </Link>
                            <div className='hr' />
                        </li>
                        <li>
                            <Link to='/History' onClick={() => setBarsActive(false)}>
                                회사연혁
                            </Link>
                            <div className='hr' />
                        </li>
                        <li>
                            <Link to='/Organization' onClick={() => setBarsActive(false)}>
                                회사조직
                            </Link>
                            <div className='hr' />
                        </li>
                        <li>
                            <Link to='/Greeting' onClick={() => setBarsActive(false)}>
                                인사말
                            </Link>
                            <div className='hr' />
                        </li>
                    </ul>
                </li>
                <li>
                    <Link to='/Project' onClick={() => setBarsActive(false)}>
                        <b>프로젝트</b>
                    </Link>
                    <div className='hr' />
                </li>
                <li>
                    <Link to='/News' onClick={() => setBarsActive(false)}>
                        <b>회사소식</b>
                    </Link>
                    <div className='hr' />
                </li>
                <li>
                    <Link to='/Contact' onClick={() => setBarsActive(false)}>
                        <b>오시는길</b>
                    </Link>
                    <div className='hr' />
                </li>
            </ul>
        </nav>
    );
}

export default Element;
