import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Nav from './elements/Nav/element'
import Footer from './elements/Footer/element';
import Home from './elements/Home/element';
import AboutService from './elements/AboutService/element';
import AboutHistory from './elements/AboutHistory/element';
import AboutOrganization from './elements/AboutOrganization/element';
import AboutGreeting from './elements/AboutGreeting/element';
import Project from './elements/Project/element';
import News from './elements/News/element';
import Contact from './elements/Contact/element';

function App() {
    return (
        <Router>
            <Nav />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/Service' element={<AboutService />} />
                <Route path='/History' element={<AboutHistory />} />
                <Route path='/Organization' element={<AboutOrganization />} />
                <Route path='/Greeting' element={<AboutGreeting />} />
                <Route path='/Project' element={<Project />} />
                <Route path='/News' element={<News />} />
                <Route path='/Contact' element={<Contact />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
