import './css.css';
import SectionTitle from '../SectionTitle/element';
import useStore from '../data';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

function Element() {
    const { setIsMain } = useStore();
    useEffect(() => {
        reset(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const reset = () => {
        const root = document.getElementById('root');
        root.scrollTo(0, 0);
        setIsMain(false);
    };

    const location = useLocation();
    const { index = -1 } = location.state || {};
    const [indexActive, setIndexActive] = useState(index);

    const { dataProject, setDataProject } = useStore();
    useEffect(() => {
        if (dataProject.length === 0)
            fetch('https://docs.google.com/spreadsheets/d/1X7jDF7W9KbqEFGBbDe-Vuyg3zLNSXCspQ8H1E5glDtw/gviz/tq?tqx=out:json&gid=1925241063')
                .then(response => response.text())
                .then(text => {
                    const json = JSON.parse(text.slice(47, -2));
                    const rows = json.table.rows;
                    setDataProject(rows);
                })
                .catch(error => console.error('Error:', error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section id='project'>
            <div className='noise' />
            <SectionTitle title={indexActive === -1 ? '프로젝트' : dataProject.slice().reverse()[indexActive].c[2].v} text={[indexActive === -1 ? '퍼스트씨엔디의 소식을 전해드립니다.' : dataProject.slice().reverse()[indexActive].c[3].v]} />
            {indexActive !== -1 && < div className='text clsBtn' onClick={() => { setIndexActive(-1); reset(); }}>
                <u><b><i className='fa-solid fa-reply' /> 목록으로</b></u>
            </div>}
            <div className='container' style={indexActive === -1 ? {} : { width: '100%', maxWidth: 'none', gridTemplateColumns: 'repeat(1, 1fr)' }}>
                {dataProject.length > 0 && dataProject.slice().reverse().map((item, i) => (
                    <div key={i} className={`item ${indexActive === i ? 'active' : indexActive === -1 ? '' : 'hide'}`} onClick={() => { setIndexActive(i); reset(); }} style={{ animationDelay: `${250 * i}ms` }}>
                        <img className='img' src={item.c[1].v} alt='' />
                        {indexActive === -1 && <div className='process'>{item.c[3].v}</div>}
                        <div className='title'>
                            <b>{item.c[2].v}</b>
                            <div className='hr' />
                        </div>
                        {indexActive === i && <>
                            <div className='text'>{item.c[4] && item.c[4].v}</div>
                            <div className='text'>{item.c[5] && item.c[5].v}</div>
                            <div className='text'>{item.c[6] && item.c[6].v}</div>
                            <div className='text'>{item.c[7] && item.c[7].v}</div>
                            <div className='text'>{item.c[8] && item.c[8].v}</div>
                            <br />
                        </>}
                    </div>
                ))}
            </div>
        </section >
    );
}

export default Element;
