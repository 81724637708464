import './css.css';
import SectionTitle from '../SectionTitle/element';
import useStore from '../data';
import { useEffect } from 'react';

function Element() {
    const { setIsMain } = useStore();
    useEffect(() => {
        reset(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const reset = () => {
        const root = document.getElementById('root');
        root.scrollTo(0, 0);
        setIsMain(false);
    };

    const { dataContact, setDataContact } = useStore();
    useEffect(() => {
        if (dataContact.length === 0)
            fetch('https://docs.google.com/spreadsheets/d/1X7jDF7W9KbqEFGBbDe-Vuyg3zLNSXCspQ8H1E5glDtw/gviz/tq?tqx=out:json&gid=1264279400')
                .then(response => response.text())
                .then(text => {
                    const json = JSON.parse(text.slice(47, -2));
                    const rows = json.table.rows;
                    setDataContact(rows[0].c);
                })
                .catch(error => console.error('Error:', error));
    }, [dataContact, setDataContact]);

    const linkMap = () => window.open(dataContact.length === 0 ? '' : dataContact.length === 0 ? '' : dataContact[1].v, '_blank', 'noopener,noreferrer');
    const linkMail = () => window.location.href = `mailto:${dataContact.length === 0 ? '' : dataContact[4].v}`;
    const linkTel = () => window.location.href = `tel:+82${dataContact.length === 0 ? '' : dataContact[5].v}`;
    const linkFax = () => window.location.href = `tel:+82${dataContact.length === 0 ? '' : dataContact[6].v}`;

    return (
        <section id='contact'>
            <div className='noise' />
            <SectionTitle title={'오시는길'} text={['퍼스트씨엔디로 오시는 길을 알려드립니다.']} />
            <div className='map' title='지도 연결' onClick={linkMap}>
                <i className='icon fa-solid fa-location-dot fa-2xl fa-fade' />
                <div className='text'>클릭시 지도 연결</div>
                <img className='img' src={dataContact.length === 0 ? '' : dataContact[0].v} alt='' />
            </div>
            <table className='container'>
                <tbody>
                    <tr title='지도 연결' onClick={linkMap} style={{ animationDelay: '250ms' }}>
                        <td className='tag'><i className='fa-solid fa-location-dot' /></td>
                        <td className='text'>
                            {dataContact.length === 0 ? '' : dataContact[2].v}
                            <br />
                            {dataContact.length === 0 ? '' : dataContact[3].v}
                        </td>
                    </tr>
                    <tr title='메일 연결' onClick={linkMail} style={{ animationDelay: '500ms' }}>
                        <td className='tag'><i className='fa-solid fa-envelope' /></td>
                        <td className='text'>
                            {dataContact.length === 0 ? '' : dataContact[4].v}
                        </td>
                    </tr>
                    <tr title='전화 연결' onClick={linkTel} style={{ animationDelay: '750ms' }}>
                        <td className='tag'><i className='fa-solid fa-phone' /></td>
                        <td className='text'>
                            {dataContact.length === 0 ? '' : dataContact[5].f}
                        </td>
                    </tr>
                    <tr title='팩스 연결' onClick={linkFax} style={{ animationDelay: '1000ms' }}>
                        <td className='tag'><i className='fa-solid fa-fax' /></td>
                        <td className='text'>
                            {dataContact.length === 0 ? '' : dataContact[6].f}
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
    );
}

export default Element;
