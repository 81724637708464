import './css.css';
import SectionTitle from '../SectionTitle/element';
import useStore from '../data';
import { useEffect, useState } from 'react';

function Element() {
    const { setIsMain } = useStore();
    useEffect(() => {
        reset(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const reset = () => {
        const root = document.getElementById('root');
        root.scrollTo(0, 0);
        setIsMain(false);
    };

    const [more, setMore] = useState(1);
    const [indexActive, setIndexActive] = useState(0);

    const { dataNews, setDataNews } = useStore();
    useEffect(() => {
        if (dataNews.length === 0)
            fetch('https://docs.google.com/spreadsheets/d/1X7jDF7W9KbqEFGBbDe-Vuyg3zLNSXCspQ8H1E5glDtw/gviz/tq?tqx=out:json&gid=504485254')
                .then(response => response.text())
                .then(text => {
                    const json = JSON.parse(text.slice(47, -2));
                    const rows = json.table.rows;
                    setDataNews(rows);
                })
                .catch(error => console.error('Error:', error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section id='news'>
            <div className='noise' />
            <SectionTitle title={'회사소식'} text={['퍼스트씨엔디의 소식을 전해드립니다.']} />
            <div className='container'>
                {dataNews.length > 0 && dataNews.slice().reverse().map((item, i) => (i < 3 * more &&
                    <div
                        key={i}
                        className={indexActive === i ? 'item active' : 'item'}
                        style={{ animationDelay: `${250 * (i % 3)}ms` }}
                        onMouseEnter={() => setIndexActive(i)}
                        onClick={() => item.c[5].v && window.open(item.c[5].v, '_blank')}
                    >
                        <i className='icon fa-regular fa-newspaper fa-xl' />
                        <div className='title'>
                            <b><u>{item.c[2].v}</u></b>
                        </div>
                        <div className='time'>
                            <i className='fa-regular fa-clock fa-sm' /> {item.c[3].f}
                        </div>
                        <div className='text'>
                            {item.c[4].v.split('\n').map((line, j) => (<div key={j}>{line}<br /></div>))}
                        </div>
                        {item.c[5].v && <>
                            <br />
                            <div className='link'>
                                <i className='fa-solid fa-link fa-sm' />
                                기사 바로가기
                            </div>
                        </>}
                    </div>
                ))}
                {3 * more < dataNews.length &&
                    <div className='item more' onClick={() => setMore(more + 1)}>
                        + 더보기 ({3 * more < dataNews.length ? 3 * more : dataNews.length}/{dataNews.length})
                    </div>
                }
            </div>
        </section>
    );
}

export default Element;
