import './css.css';
import useStore from '../data';
import { Link } from 'react-router-dom';
import { useEffect, useState, useRef, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { ReactTyped } from 'react-typed';

function Element() {
    const { setIsMain } = useStore();
    useEffect(() => {
        reset(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const reset = () => {
        const root = document.getElementById('root');
        root.scrollTo(0, 0);
        setIsMain(true);
    };

    const [index, setIndex] = useState(1);

    const setScroll = useCallback((i) => {
        const root = document.getElementById('root');
        const sections = Array.from(root.children);
        const section = sections[i];
        for (var item of sections) {
            const j = sections.indexOf(item);
            if (j !== 0) item.style.transform = `translateY(${window.innerHeight - section.clientHeight - section.offsetTop}px)`;
        }
        setIndex(i);
    }, []);

    useEffect(() => {
        const root = document.getElementById('root');
        const count = root.children.length - 1;

        const wheel = (e) => {
            if (e.deltaY < 0) setScroll(index > 1 ? index - 1 : 1);
            else if (e.deltaY > 0) setScroll(index < count ? index + 1 : count);
        };
        let startX, startY;
        const touchstart = (e) => {
            startX = e.touches[0].clientX;
            startY = e.touches[0].clientY;
        }
        const touchend = (e) => {
            if (!startY) return startY = null;
            let deltaX = startX - e.changedTouches[0].clientX;
            let deltaY = startY - e.changedTouches[0].clientY;
            if (Math.abs(deltaX) > Math.abs(deltaY) || Math.abs(deltaY) < 50) return startY = null;
            if (deltaY < 0) setScroll(index > 1 ? index - 1 : 1);
            else if (deltaY > 0) setScroll(index < count ? index + 1 : count);
            startX = null;
            startY = null;
        };

        root.addEventListener('wheel', wheel);
        root.addEventListener('touchstart', touchstart);
        root.addEventListener('touchend', touchend);
        return () => {
            root.removeEventListener('wheel', wheel);
            root.removeEventListener('touchstart', touchstart);
            root.removeEventListener('touchend', touchend);
        };
    }, [index, setScroll]);

    const { dataService, setDataService } = useStore();
    useEffect(() => {
        if (dataService.length === 0)
            fetch('https://docs.google.com/spreadsheets/d/1X7jDF7W9KbqEFGBbDe-Vuyg3zLNSXCspQ8H1E5glDtw/gviz/tq?tqx=out:json&gid=94095624')
                .then(response => response.text())
                .then(text => {
                    const json = JSON.parse(text.slice(47, -2));
                    const rows = json.table.rows;
                    setDataService(rows);
                })
                .catch(error => console.error('Error:', error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const swiperProjectRef = useRef(null);
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);
    const [isActiveProject, setIsActiveProject] = useState(-1);
    const [projectSlidePerView, setProjectSlidePerView] = useState(Math.ceil(window.innerWidth / 720));
    useEffect(() => {
        const handleResize = () => setProjectSlidePerView(Math.ceil(window.innerWidth / 720));
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const { dataProject, setDataProject } = useStore();
    useEffect(() => {
        if (dataProject.length === 0)
            fetch('https://docs.google.com/spreadsheets/d/1X7jDF7W9KbqEFGBbDe-Vuyg3zLNSXCspQ8H1E5glDtw/gviz/tq?tqx=out:json&gid=1925241063')
                .then(response => response.text())
                .then(text => {
                    const json = JSON.parse(text.slice(47, -2));
                    const rows = json.table.rows;
                    setDataProject(rows);
                })
                .catch(error => console.error('Error:', error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <section className='section main'>
                <div className='noise' />
                <Swiper
                    speed={1500}
                    loop={true}
                    autoplay={{ delay: 5000 }}
                    effect='fade'
                    fadeEffect={{ crossFade: true }}
                    modules={[Autoplay, EffectFade]}
                >
                    <SwiperSlide>
                        <img
                            className='img show'
                            src='https://drive.google.com/thumbnail?sz=w1920&id=1tpb5gTkHPBVs_R3Ssc5thGXMUDZu5MuS'
                            alt=''
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <video
                            className='video'
                            playsInline muted autoPlay loop
                            onPlay={(e) => e.target.playbackRate = .5}
                        >
                            <source src={require(`./main-0.mp4`)} type='video/mp4' />
                            Your browser does not support the video tag.
                        </video>
                    </SwiperSlide>
                </Swiper>
                <div className='text'>
                    <ReactTyped
                        strings={['First is Best!', 'Best is First!']}
                        typeSpeed={100}
                        backSpeed={50}
                        backDelay={1500}
                        showCursor={false}
                        loop
                    />
                    <br />
                    <br />
                    <div className='bold' style={{ animationDelay: '250ms' }}>
                        <b>최고의 결과를 위해 과감히 퍼스트가 되고,</b>
                    </div>
                    <div className='bold' style={{ animationDelay: '500ms' }}>
                        <b>베스트를 다해 최고임을 증명합니다.</b>
                    </div>
                </div>
            </section>
            <section className='section service'>
                <div className='noise' />
                <Link to='/Service'>
                    {dataService.length > 0 && dataService.map((item, i) => (
                        <div key={i} className='item' style={{ animationDelay: `${250 * i}ms` }}>
                            <img className='img show' src={item.c[1].v} alt='' />
                            <div className='title'><b>{item.c[2].v}</b></div>
                            <div className='text'>{item.c[3].v}</div>
                        </div>
                    ))}
                </Link>
            </section>
            <section className='section project'>
                <div className='noise' />
                {dataProject.length > 0 && dataProject.slice().reverse().map((item, i) => (
                    <img key={i} className={`img ${isActiveProject === i ? 'show' : ''}`} src={item.c[1].v} alt='' />
                ))}
                <Swiper
                    slidesPerView={projectSlidePerView}
                    speed={1000}
                    onSwiper={(swiper) => (swiperProjectRef.current = swiper)}
                    onSlideChange={(swiper) => {
                        setIsBeginning(swiper.isBeginning);
                        setIsEnd(swiper.isEnd);
                    }}
                >
                    {dataProject.length > 0 && dataProject.slice().reverse().map((item, i) => (
                        <SwiperSlide key={i} onMouseEnter={() => setIsActiveProject(i)} onMouseLeave={() => setIsActiveProject(-1)}>
                            <img className='img show' src={item.c[1].v} alt='' />
                            <Link to='/Project' state={{ index: i }}>
                                <div className='process'>{item.c[3].v}</div>
                                <br />
                                <div className='title'>
                                    <b>{item.c[2].v}</b>
                                    <div className='hr' />
                                </div>
                                <br />
                                <div className='text'>{item.c[4] && item.c[4].v}</div>
                                <div className='text'>{item.c[5] && item.c[5].v}</div>
                                <div className='text'>{item.c[6] && item.c[6].v}</div>
                                <div className='text'>{item.c[7] && item.c[7].v}</div>
                                <div className='text'>{item.c[8] && item.c[8].v}</div>
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div
                    className={`swiper-btn prev ${isBeginning ? 'hide' : ''}`}
                    onClick={() => swiperProjectRef.current.slidePrev()}
                >
                    <i className='fa-solid fa-arrow-left fa-2xl fa-fade' />
                </div>
                <div
                    className={`swiper-btn next ${isEnd ? 'hide' : ''}`}
                    onClick={() => swiperProjectRef.current.slideNext()}
                >
                    <i className='fa-solid fa-arrow-right fa-2xl fa-fade' />
                </div>
            </section>
        </>
    );
}

export default Element;
