import { create } from 'zustand';

const useStore = create((set) => ({
    dataService: [],
    setDataService: (data) => set({ dataService: data }),
    dataHistory: [],
    setDataHistory: (data) => set({ dataHistory: data }),
    dataOrganization: [],
    setDataOrganization: (data) => set({ dataOrganization: data }),
    dataSubsidiary: [],
    setDataSubsidiary: (data) => set({ dataSubsidiary: data }),
    dataProject: [],
    setDataProject: (data) => set({ dataProject: data }),
    dataNews: [],
    setDataNews: (data) => set({ dataNews: data }),
    dataContact: [],
    setDataContact: (data) => set({ dataContact: data }),
    isMain: true,
    setIsMain: (data) => set({ isMain: data }),
}));

export default useStore;