import './css.css';

function Element({ title, text = [] }) {
    return (
        <div className='sectionTitle'>
            <div className='_title'><b>{title}</b></div>
            {text.map((e, i) => (<div key={i} className='_text'>{e}</div>))}
        </div>
    );
}

export default Element;
