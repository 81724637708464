import './css.css';
import useStore from '../data';
import SectionTitle from '../SectionTitle/element';
import { useEffect } from 'react';

function Element() {
    const { setIsMain } = useStore();
    useEffect(() => {
        reset(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const reset = () => {
        const root = document.getElementById('root');
        root.scrollTo(0, 0);
        setIsMain(false);
    };

    return (
        <section id='greeting'>
            <div className='noise' />
            <SectionTitle title={'인사말'} text={['-']} />
            <div className='container'>
                <i className='icon fa-regular fa-comment-dots fa-xl' style={{ animationDelay: '250ms' }} />
                <div style={{ animationDelay: '500ms' }}>퍼스트씨엔디는 2016년 종합부동산 전문기업으로 출범 후 언제나 새로운 시각과 선구자적 시선으로 급변하는 부동산 시장과 다양한 고객 니즈를 만족시키고자 최선의 노력을 다하고 있습니다.</div>
                <br />
                <div style={{ animationDelay: '750ms' }}>나아가 열린경영, 투명경영, 책임경영의 이념 아래 고객과 사회로부터 신뢰받고 존경 받는 기업.</div>
                <br />
                <div style={{ animationDelay: '1000ms' }}>창조적이고 미래 지향적인 사고로 도전과 실패를 두려워 하지 않는 기업.</div>
                <br />
                <div style={{ animationDelay: '1250ms' }}>항상 주인의식을 갖고 최대의 만족감과 감동을 줄 수 있는 기업으로 성장해 가겠습니다.</div>
                <br />
                <div style={{ animationDelay: '1500ms' }}>감사합니다.</div>
                <br />
                <br />
                <br />
                <div className='job' style={{ animationDelay: '1750ms' }}>퍼스트씨엔디 대표이사</div>
                <div className='ceo' style={{ animationDelay: '1750ms' }}><b><u>이 원 기</u></b></div>
            </div>
        </section>
    );
}

export default Element;
